

.emoji-container {
  position: relative;
  display: inline-block;

  .emoji-button {
    position: absolute;
    bottom: 80px;
    right: 10px;
    font-size: 15px;
    padding: 5px;
    cursor: pointer;
    background-color: #f0f0f0;
    border: 1px solid #ccc;
    border-radius: 5px;
  }

  .emoji-picker {
    position: absolute;
    width: 120px;
    bottom: 110px;
    right: 10px;
    display: flex;
    flex-wrap: wrap;
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 10px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  }

  .emoji {
    font-size: 12px;
    padding: 5px;
    cursor: pointer;
  }

  .emoji:hover {
    background-color: #f0f0f0;
  }
}

@media screen and (min-width: 1400px){
  .news_block{
    width: calc(100% - 40px);
    margin-top: 30px;

    .news_block_title{
      display: flex;
      justify-content: flex-start;
      align-items: flex-end;
      .news_block_title_name{
        font-size: 24pt;
        margin-right: 50px;
        font-weight: 800;
        height: 35px;
        color: rgb(18, 19, 56);
      }
      .news_block_title_more{
        //margin-top: 5px;
        font-size: 14pt;
        cursor: pointer;
      }
      .news_block_title_create{
        font-size: 14pt;
        padding: 5px 30px;
        margin: 0 0 0 20px;
        border: 1px solid #CCC;
        border-radius: 8px;
        color: #CCC;
        cursor: pointer;
        transition: 0.2s;
      }
      .news_block_title_create:hover{
        border: 1px solid #454545;
        color: #454545;
        transition: 0.2s;
      }
    }
    .news_block_titoffer{
      width: 100%;
      display: flex;
      flex-direction: column;
      //justify-content: flex-start;
      //align-items: flex-end;
      .news_block_title_offer{
        width: 100%;
        display: flex;
        justify-content: center;
        font-size: 14pt;
        padding: 15px 0px;
        margin: 10px 0 0 0px;
        border: 1px solid #CCC;
        border-radius: 8px;
        color: rgb(18, 19, 56);
        cursor: pointer;
        transition: 0.2s;
        background-color: #FFFFFF;
      }

      .news_block_title_offer:hover{
        border: 1px solid #454545;
        color: #454545;
        transition: 0.2s;
      }
      .chat-gpt{
        margin-top: 10px;
      }
    }

    .news_block_list{
      width: 100%;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      .news_block_list_box {
        width: 28%;
        min-width: 260px;
        padding: 1%;
        min-height: 350px;
        border: none;
        margin-top: 20px;
        border-radius: 10px;
        background-color: #FFF;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;
        cursor: pointer;
        .news_block_list_box_img{
          width: 100%;
          //height: 260px;
          background-size: cover;
          background-repeat: no-repeat;
          background-position: center center;
          border-radius: 7px;
        }
        .news_block_list_box_content{
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: flex-start;
          width: 100%;
          height:calc(100% - 180px);

          .news_block_list_box_content_a{
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            .news_block_list_box_content_xyz{
              display: flex;
              justify-content: flex-start;
              margin-top: 5px;
              .news_block_list_box_content_xyz_x{
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 1px 15px;
                border: 1px solid #454545;
                border-radius: 10px;
                margin-right: 10px;
              }
              .news_block_list_box_content_xyz_y{
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 1px 15px;
                border: 1px solid #454545;
                border-radius: 10px;
                margin-right: 10px;
              }
              .news_block_list_box_content_xyz_z{
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 1px 15px;
                border: 1px solid #454545;
                border-radius: 10px;
                margin-right: 10px;
              }
            }

            .news_block_list_box_content_title{
              font-weight: 800;
              margin-top: 5px;
            }
            .news_block_list_box_content_description{}
          }
          .news_block_list_box_content_btns{
            display: flex;
            justify-content: space-between;
            align-items: flex-end;
            width: 100%;
            div{
              margin-right: 15px;
            }
            .rigth{
              display: flex;
              justify-content: right;
            }
            .left{
              display: flex;
              flex-direction: row;
              justify-content: left;
            }
            .views{
              margin: 0 10px;
            }
            .news_block_list_box_content_btns_date{

            }
            .news_block_list_box_content_btns_like{}
            .news_block_list_box_content_btns_comment{}
          }



        }

      }
    }
  }
  .create_new_post{
    width: 100%;
    //font-size: 16pt;
    .create_new_post_title{
      width: calc(100% - 40px);
      font-size: 18pt;
      font-weight: 600;
      color:rgba(18,19,56,1);
      padding: 0 20px;
    }
    .create_new_post_tools{
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;
      margin-top: 10px;
      padding: 10px 20px;

      .create_new_post_tools_plusblock{}
      .create_new_post_tools_devbtn{}
      .create_new_post_tools_publish{}
      div{
        margin-right: 20px;
        padding: 20px 20px;
        border: 1px solid #CCC;
        border-radius: 4px;
        cursor: pointer;
        color: #454545;
        transition: 0.2s;
      }
      div:hover{
        color: rgba(18,19,56,1);
        border: 1px solid rgba(18,19,56,1);
        transition: 0.2s;
      }
    }

    .create_new_post_worklist{

      width: calc(100% - 40px);
      padding: 20px;
      background-color: #FFF;
      border-radius: 10px;
      @media screen and (min-width: 600px) {
        .create_new_post_worklist_header{
          width: calc(100% - 46px);
          padding: 20px;
          border: 3px dotted #CCC;
          border-radius: 10px;
          font-size: 24pt;
          font-weight: 600;
          color:rgba(18,19,56,1);
          text-align: center;
          outline: none;
          transition: .2s;
        }
      }
      @media screen and (max-width: 600px) {
        .create_new_post_worklist_header{
          width: calc(100% - 46px);
          padding: 20px;
          border: 3px dotted #CCC;
          border-radius: 10px;
          font-size: 16pt;
          font-weight: 600;
          color:rgba(18,19,56,1);
          text-align: center;
          outline: none;
          transition: .2s;
        }
      }


      .create_new_post_worklist_header:hover{
        border: 3px dotted #454545;
        transition: .2s;
      }

      .create_new_post_worklist_header:active{
        border: 3px dotted #454545;
        transition: .2s;
      }

      .create_new_post_worklist_header:focus{
        border: 3px dotted #454545;
        transition: .2s;
      }

      .create_new_post_worklist_mainimg{
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #E3E3E3;
        width: calc(100% - 46px);
        height: 200px;
        border: 3px dotted #CCC;
        margin: 10px 0 0 0;
        padding: 10px 20px;
        border-radius: 10px;
        cursor: pointer;
        transition: .2s;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;
        i{
          color: #CCC;
          font-size: 24pt;
          border: 1px solid #CCC;
          padding: 10px;
          border-radius: 3px;
          transition: .2s;
        }
      }
      .create_new_post_worklist_mainimg:hover{
        transition: .2s;
        border: 3px dotted #454545;
        i{
          color: #454545;
          border: 1px solid #454545;
          transition: .2s;
        }
      }



      .create_new_post_worklist_content{
        margin-top: 10px;
        display: flex;
        flex-direction: column;
        .create_new_post_worklist_content_smalltext{
        }
        .create_new_post_worklist_content_title{
          font-size: 18pt;
        }
        .create_new_post_worklist_content_longtext{

        }
        .create_new_post_worklist_content_imgtext{
          display: flex;
          justify-content: space-between;
          div, textarea{
            width: calc(90% / 2)
          }
          .create_new_post_worklist_content_imgtext_img{
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: #E3E3E3;
            height: 400px;
            border: 3px dotted #CCC;
            padding: 10px 20px;
            border-radius: 10px;
            cursor: pointer;
            transition: .2s;
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center center;
            i{
              color: #CCC;
              font-size: 24pt;
              border: 1px solid #CCC;
              padding: 10px;
              border-radius: 3px;
              transition: .2s;
            }
          }
          .create_new_post_worklist_content_imgtext_img:hover{
            transition: .2s;
            border: 3px dotted #454545;
            i{
              color: #454545;
              border: 1px solid #454545;
              transition: .2s;
            }
          }
          .create_new_post_worklist_content_imgtext_text{
            font-size: 18pt;
          }
        }
        div, input, textarea{
          width: calc(100% - 46px);
          padding: 10px 20px;
          min-height: 120px;
          border: 3px dotted #CCC;
          border-radius: 10px;
          //margin: 10px 0 0 0;
        }

        div, input, textarea:hover{
          border: 3px dotted #454545;
          transition: .2s;
        }

        div, input, textarea:active{
          border: 3px dotted #454545;
          transition: .2s;
        }

        textarea:focus{
          border: 3px dotted #454545;
          transition: .2s;
        }

        input:focus{
          border: 3px dotted #454545;
          transition: .2s;
        }

        div:focus{
          border: 3px dotted #454545;
          transition: .2s;
        }

        .plus_btn_create_strock{
          display: flex;
          border: none;
          min-height: 10px;
          transition: .2s;
        }

        .plus_btn_create_strock_btn{
          min-height: 20px;
          border: 1px solid #CCC;
          color: #CCC;
          width: auto;
          max-height: 30px !important;
          cursor: pointer;
          margin-right: 10px;
          padding: 5px 30px;
        }
        .plus_btn_create_strock_btn:hover{
          color: #454545;
          border: 1px solid #454545;

        }

        .plus_btn_create_strock:hover{
          div{
            display: flex;
          }
        }

        .create_new_post_worklist_content_tripleimgs{
          display: flex;
          justify-content: space-between;
          .create_new_post_worklist_content_tripleimgs_img{
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: #E3E3E3;
            height: 400px;
            border: 3px dotted #CCC;
            padding: 10px 20px;
            border-radius: 10px;
            cursor: pointer;
            transition: .2s;
            width: calc(85% / 3);
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center center;
            i{
              color: #CCC;
              font-size: 24pt;
              border: 1px solid #CCC;
              padding: 10px;
              border-radius: 3px;
              transition: .2s;
            }
          }
          .create_new_post_worklist_content_tripleimgs_img:hover{
            transition: .2s;
            border: 3px dotted #454545;
            i{
              color: #454545;
              border: 1px solid #454545;
              transition: .2s;
            }
          }
        }

      }

    }

  }
  .view_new_post{
    width: 100%;
    //font-size: 16pt;
    .view_new_post_title{
      width: calc(100% - 40px);
      font-size: 18pt;
      font-weight: 600;
      color:rgba(18,19,56,1);
      padding: 0 20px;
    }
    .view_new_post_tools{
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      margin-top: 10px;
      padding: 10px 20px;

      span{
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .view_new_post_tools_plusblock{}
      .view_new_post_tools_devbtn{}
      .view_new_post_tools_publish.disable{
        display: none;
      }
      div,a{
        margin-right: 20px;
        padding: 20px 20px;
        border: 1px solid #CCC;
        border-radius: 4px;
        cursor: pointer;
        color: #454545;
        transition: 0.2s;
      }
      div:hover,a:hover{
        color: rgba(18,19,56,1);
        border: 1px solid rgba(18,19,56,1);
        transition: 0.2s;
      }
    }

    .view_new_post_worklist{
      max-width: 1000px;
      padding: 20px;
      border-radius: 10px;
      .view_new_post_worklist_header{
        width: calc(100% - 40px);
        padding: 20px;
        font-size: 24pt;
        font-weight: 600;
        color:rgba(18,19,56,1);
        text-align: center;
        outline: none;
        transition: .2s;
      }

      .view_new_post_worklist_mainimg{
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #E3E3E3;

        background-size: contain;
        background-position: center center;
        width:100%;

        border-radius: 10px;
        transition: .2s;

      }.view_new_post_worklist_mainimg.big{
         height: 400px;
       }
      .biggest{
        height: 500px;
      }
      .view_new_post_worklist_content{
        margin-top: 10px;
        display: flex;
        flex-direction: column;
        .view_new_post_worklist_content_smalltext{
          font-size: 16pt;
          font-weight: 600;
        }
        .view_new_post_worklist_content_title{
          font-size: 18pt;
        }
        .view_new_post_worklist_content_longtext{
          font-size: 16pt;
        }
        .view_new_post_worklist_content_imgtext{
          display: flex;
          justify-content: space-between;
          div{
            width: calc(90% / 2)
          }
          .view_new_post_worklist_content_imgtext_img{
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: #E3E3E3;
            background-image: url("img/bundle.png");
            background-size: cover;
            background-position: center center;
            padding: 10px 20px;
            border-radius: 10px;
            cursor: pointer;
            transition: .2s;

          }

          .view_new_post_worklist_content_imgtext_text{
            font-size: 16pt;
            font-weight: 500;
          }
        }
        div{
          width: calc(100% - 40px);
          padding: 10px 20px;
          border-radius: 10px;
          //margin: 10px 0 0 0;
        }




        .view_new_post_worklist_content_tripleimgs{
          display: flex;
          justify-content: space-between;
          .view_new_post_worklist_content_tripleimgs_img{
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: #E3E3E3;
            padding: 10px 20px;
            border-radius: 10px;
            cursor: pointer;
            transition: .2s;
            width: calc(85% / 3);

            background-position: center center;
            background-size: cover;
          }

        }

      }

    }

  }
  .red-border{
    border: 3px dotted #FFC0CB !important;
  }
  .history_messs{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    max-width: calc(100vw - 350px);
    background-color: #FFFFFF;
    border: 1px solid #CCC;
    margin-top: 40px;
    border-radius: 20px;
    //overflow-x: auto;
    .title{
      margin: 20px;
      text-transform: uppercase;
      font-weight: 500;
      font-size: 1.4rem;
    }
    .history_mess_pen {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      width: calc(100% - 40px);
      padding: 20px;
      position: relative;
      .history_mess_pen_letter{
        width: calc(100% - 20px);
        height: 100px;
        border-radius: 10px;
        border: 1px solid #CCC;
        resize: none;
        outline: none;
        padding: 10px;
      }
      .history_mess_pen_letter:focus{
        border: 1px solid #329DD9;
      }
      .history_mess_pen_btn{
        padding: 10px 30px;
        margin-top: 10px;
        background-color: rgb(18, 19, 56);
        color: #FFFFFF;
        border-radius: 10px;
        cursor: pointer;
        i{
          font-size: 16pt;
          margin-left: 10px;
        }
      }
      .history_mess_pen_btn:hover{
        opacity: 0.8;
      }
    }

    .history_mess_list{
      overflow-x: auto;
      width: calc(100% - 20px);

      .history_mess_list_block{
        margin-bottom: 20px;
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        .history_mess_list_block_ava{
          width: 60px;
          height: 60px;
          background-size: 100%;
          background-position: center center;
          background-repeat: no-repeat;
          border-radius: 50%;
          margin: 20px;
        }
        .history_mess_list_block_content{
          width: calc(100% - 120px);
          padding: 10px;
          border-radius: 10px;
          background-color: #F1F1F1;

          .history_mess_list_block_content_name{
            display: flex;
            justify-content: space-between;
            font-weight: 600;
            margin: 10px;
            .icons{
              display: flex;
              justify-content: flex-start;
              align-items: center;
            }
            i{
              margin-left: 10px;
              cursor: pointer;
              font-size: 1.2rem;
              color: #cccccc;
            }
            i:hover{
              color: black;
            }
            .small{
              font-size: 0.8rem;
            }
          }
          .history_mess_list_block_content_message{
            margin: 10px;
            max-width: 100%;
          }
          .history_mess_list_block_content_dateandstatus{
            width: 100%;
            display: flex;
            justify-content: space-between;
            font-size: 10pt;
            .history_mess_list_block_content_date{
              margin-right: 20px;
            }
            .history_mess_list_block_content_status{
              margin-right: 10px;
            }
          }
          .change-text{
            width: 90%;
            border-radius: 10px;
            border: 1px solid #CCC;
            resize: none;
            outline: none;
            padding: 10px;
            margin: 5px 0 10px 0;
          }
          .cancel,.savechange{
            margin-top: -2px;
            font-size: 0.9rem;
            font-weight: 600;
            text-transform: uppercase;
            color: #aaa;
            cursor: pointer;
            margin-left: 15px;
          }
          .savechange:hover,.cancel:hover{
            color: #333;
          }
          .savechange{

          }

        }

      }
      .autorch{
        background-color: #FFFFFF !important;
        border: 1px solid #CCC;
      }
    }
    .showmore{
      cursor: pointer;
      margin: 10px 40px 30px;
      font-weight: 700;
      color: #aaa;
    }
    .showmore:hover{
      color: #333333;
      text-decoration: underline;
    }
  }
}

@media screen and (min-width: 800px) and (max-width: 1400px){
  .news_block{
    width: calc(100% );
    margin-top: 50px;

    .news_block_title{
      display: flex;
      justify-content: flex-start;
      align-items: flex-end;

      .news_block_title_name{
        font-size: 24pt;
        margin-right: 50px;
        font-weight: 800;
        height: 35px;
        color: rgb(18, 19, 56);
      }
      .news_block_title_more{
        //margin-top: 5px;
        font-size: 14pt;
        cursor: pointer;
      }
      .news_block_title_create{
        font-size: 14pt;
        padding: 5px 30px;
        margin: 0 0 0 20px;
        border: 1px solid #CCC;
        border-radius: 8px;
        color: #CCC;
        cursor: pointer;
        transition: 0.2s;
      }
      .news_block_title_create:hover{
        border: 1px solid #454545;
        color: #454545;
        transition: 0.2s;
      }
    }
    .news_block_titoffer{
      width: 100%;
      display: flex;
      flex-direction: column;
      //justify-content: flex-start;
      //align-items: flex-end;
      .news_block_title_offer{
        width: 100%;
        display: flex;
        justify-content: center;
        font-size: 14pt;
        padding: 15px 0px;
        margin: 10px 0 0 0px;
        border: 1px solid #CCC;
        border-radius: 8px;
        color: rgb(18, 19, 56);
        cursor: pointer;
        transition: 0.2s;
        background-color: #FFFFFF;
      }
      .news_block_title_offer:hover{
        border: 1px solid #454545;
        color: #454545;
        transition: 0.2s;
      }
    }


    .news_block_list{
      width: 100%;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      .news_block_list_box {
        width: 46%;
        min-width: 260px;
        min-height: 350px;
        padding: 1%;
        margin: 1%;
        border: none;
        margin-top: 20px;
        border-radius: 10px;
        background-color: #FFF;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;
        cursor: pointer;
        .news_block_list_box_img{
          width: 100%;
          //height: 250px;
          background-size: cover;
          background-repeat: no-repeat;
          background-position: center center;
          border-radius: 7px;
        }
        .news_block_list_box_content{
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: flex-start;
          width: 100%;
          height:calc(100% - 180px);

          .news_block_list_box_content_a{
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            .news_block_list_box_content_xyz{
              display: flex;
              justify-content: flex-start;
              margin-top: 5px;
              .news_block_list_box_content_xyz_x{
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 1px 15px;
                border: 1px solid #454545;
                border-radius: 10px;
                margin-right: 10px;
              }
              .news_block_list_box_content_xyz_y{
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 1px 15px;
                border: 1px solid #454545;
                border-radius: 10px;
                margin-right: 10px;
              }
              .news_block_list_box_content_xyz_z{
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 1px 15px;
                border: 1px solid #454545;
                border-radius: 10px;
                margin-right: 10px;
              }
            }

            .news_block_list_box_content_title{
              font-weight: 800;
              margin-top: 5px;
            }
            .news_block_list_box_content_description{}
          }
          .news_block_list_box_content_btns{
            display: flex;
            justify-content: space-between;
            align-items: flex-end;
            width: 100%;
            div{
              margin-right: 15px;
            }
            .rigth{
              display: flex;
              justify-content: right;
            }
            .left{
              display: flex;
              flex-direction: row;
              justify-content: left;
            }
            .views{
              margin: 0 10px;
            }
            .news_block_list_box_content_btns_date{

            }
            .news_block_list_box_content_btns_like{}
            .news_block_list_box_content_btns_comment{}
          }



        }

      }
    }
  }
  .create_new_post{
    width: 100%;
    //font-size: 16pt;
    .create_new_post_title{
      width: calc(100% - 40px);
      font-size: 18pt;
      font-weight: 600;
      color:rgba(18,19,56,1);
      padding: 0 20px;
    }
    .create_new_post_tools{
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;
      margin-top: 10px;
      padding: 10px 20px;

      .create_new_post_tools_plusblock{}
      .create_new_post_tools_devbtn{}
      .create_new_post_tools_publish{}
      div{
        margin-right: 20px;
        padding: 20px 20px;
        border: 1px solid #CCC;
        border-radius: 4px;
        cursor: pointer;
        color: #454545;
        transition: 0.2s;
      }
      div:hover{
        color: rgba(18,19,56,1);
        border: 1px solid rgba(18,19,56,1);
        transition: 0.2s;
      }
    }

    .create_new_post_worklist{

      width: calc(100% - 40px);
      padding: 20px;
      background-color: #FFF;
      border-radius: 10px;
      .create_new_post_worklist_header{
        width: calc(100% - 46px);
        padding: 20px;
        border: 3px dotted #CCC;
        border-radius: 10px;
        font-size: 24pt;
        font-weight: 600;
        color:rgba(18,19,56,1);
        text-align: center;
        outline: none;
        transition: .2s;
      }

      .create_new_post_worklist_header:hover{
        border: 3px dotted #454545;
        transition: .2s;
      }

      .create_new_post_worklist_header:active{
        border: 3px dotted #454545;
        transition: .2s;
      }

      .create_new_post_worklist_header:focus{
        border: 3px dotted #454545;
        transition: .2s;
      }

      .create_new_post_worklist_mainimg{
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #E3E3E3;
        width: calc(100% - 46px);
        height: 200px;
        border: 3px dotted #CCC;
        margin: 10px 0 0 0;
        padding: 10px 20px;
        border-radius: 10px;
        cursor: pointer;
        transition: .2s;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;
        i{
          color: #CCC;
          font-size: 24pt;
          border: 1px solid #CCC;
          padding: 10px;
          border-radius: 3px;
          transition: .2s;
        }
      }
      .create_new_post_worklist_mainimg:hover{
        transition: .2s;
        border: 3px dotted #454545;
        i{
          color: #454545;
          border: 1px solid #454545;
          transition: .2s;
        }
      }



      .create_new_post_worklist_content{
        margin-top: 10px;
        display: flex;
        flex-direction: column;
        .create_new_post_worklist_content_smalltext{
        }
        .create_new_post_worklist_content_title{
          font-size: 18pt;
        }
        .create_new_post_worklist_content_longtext{

        }
        .create_new_post_worklist_content_imgtext{
          display: flex;
          justify-content: space-between;
          div, textarea{
            width: calc(90% / 2)
          }
          .create_new_post_worklist_content_imgtext_img{
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: #E3E3E3;
            height: 400px;
            border: 3px dotted #CCC;
            padding: 10px 20px;
            border-radius: 10px;
            cursor: pointer;
            transition: .2s;
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center center;
            i{
              color: #CCC;
              font-size: 24pt;
              border: 1px solid #CCC;
              padding: 10px;
              border-radius: 3px;
              transition: .2s;
            }
          }
          .create_new_post_worklist_content_imgtext_img:hover{
            transition: .2s;
            border: 3px dotted #454545;
            i{
              color: #454545;
              border: 1px solid #454545;
              transition: .2s;
            }
          }
          .create_new_post_worklist_content_imgtext_text{
            font-size: 18pt;
          }
        }
        div, input, textarea{
          width: calc(100% - 46px);
          padding: 10px 20px;
          min-height: 120px;
          border: 3px dotted #CCC;
          border-radius: 10px;
          //margin: 10px 0 0 0;
        }

        div, input, textarea:hover{
          border: 3px dotted #454545;
          transition: .2s;
        }

        div, input, textarea:active{
          border: 3px dotted #454545;
          transition: .2s;
        }

        textarea:focus{
          border: 3px dotted #454545;
          transition: .2s;
        }

        input:focus{
          border: 3px dotted #454545;
          transition: .2s;
        }

        div:focus{
          border: 3px dotted #454545;
          transition: .2s;
        }

        .plus_btn_create_strock{
          display: flex;
          border: none;
          min-height: 10px;
          transition: .2s;
        }

        .plus_btn_create_strock_btn{
          min-height: 20px;
          border: 1px solid #CCC;
          color: #CCC;
          width: auto;
          max-height: 30px !important;
          cursor: pointer;
          margin-right: 10px;
          padding: 5px 30px;
        }
        .plus_btn_create_strock_btn:hover{
          color: #454545;
          border: 1px solid #454545;

        }

        .plus_btn_create_strock:hover{
          div{
            display: flex;
          }
        }

        .create_new_post_worklist_content_tripleimgs{
          display: flex;
          justify-content: space-between;
          .create_new_post_worklist_content_tripleimgs_img{
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: #E3E3E3;
            height: 400px;
            border: 3px dotted #CCC;
            padding: 10px 20px;
            border-radius: 10px;
            cursor: pointer;
            transition: .2s;
            width: calc(85% / 3);
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center center;
            i{
              color: #CCC;
              font-size: 24pt;
              border: 1px solid #CCC;
              padding: 10px;
              border-radius: 3px;
              transition: .2s;
            }
          }
          .create_new_post_worklist_content_tripleimgs_img:hover{
            transition: .2s;
            border: 3px dotted #454545;
            i{
              color: #454545;
              border: 1px solid #454545;
              transition: .2s;
            }
          }
        }

      }

    }

  }
  .view_new_post{
    width: 100%;
    //font-size: 16pt;
    .view_new_post_title{
      width: calc(100% - 40px);
      font-size: 18pt;
      font-weight: 600;
      color:rgba(18,19,56,1);
      padding: 0 20px;
    }
    .view_new_post_tools{
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      margin-top: 10px;
      padding: 10px 20px;

      span{
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .view_new_post_tools_plusblock{}
      .view_new_post_tools_devbtn{}
      .view_new_post_tools_publish.disable{
        display: none;
      }
      div,a{
        margin-right: 20px;
        padding: 20px 20px;
        border: 1px solid #CCC;
        border-radius: 4px;
        cursor: pointer;
        color: #454545;
        transition: 0.2s;
      }
      div:hover,a:hover{
        color: rgba(18,19,56,1);
        border: 1px solid rgba(18,19,56,1);
        transition: 0.2s;
      }
    }

    .view_new_post_worklist{

      width:100%;

      border-radius: 10px;
      .view_new_post_worklist_header{
        width: calc(100% - 40px);
        padding: 20px;
        font-size: 24pt;
        font-weight: 600;
        color:rgba(18,19,56,1);
        text-align: center;
        outline: none;
        transition: .2s;
      }

      .view_new_post_worklist_mainimg{
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #E3E3E3;
        background-size: contain;
        background-position: center center;
        width: 100%;
        border-radius: 10px;
        transition: .2s;

      }.view_new_post_worklist_mainimg.big{
         height: 400px;
       }
      .biggest{
        height: 500px;
      }
      .view_new_post_worklist_content{
        margin-top: 10px;
        display: flex;
        flex-direction: column;
        .view_new_post_worklist_content_smalltext{
          font-size: 16pt;
          font-weight: 600;
        }
        .view_new_post_worklist_content_title{
          font-size: 18pt;
        }
        .view_new_post_worklist_content_longtext{
          font-size: 16pt;
        }
        .view_new_post_worklist_content_imgtext{
          display: flex;
          justify-content: space-between;
          div{
            width: calc(90% / 2)
          }
          .view_new_post_worklist_content_imgtext_img{
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: #E3E3E3;
            background-image: url("img/bundle.png");
            background-size: cover;
            background-position: center center;
            padding: 10px 20px;
            border-radius: 10px;
            cursor: pointer;
            transition: .2s;

          }

          .view_new_post_worklist_content_imgtext_text{
            font-size: 12pt;
            font-weight: 500;
          }
        }
        div{
          width: calc(100% - 40px);
          padding: 10px 20px;
          border-radius: 10px;
          //margin: 10px 0 0 0;
        }




        .view_new_post_worklist_content_tripleimgs{
          display: flex;
          justify-content: space-between;
          .view_new_post_worklist_content_tripleimgs_img{
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: #E3E3E3;
            padding: 10px 20px;
            border-radius: 10px;
            cursor: pointer;
            transition: .2s;
            width: calc(80% / 3);

            background-position: center center;
            background-size: cover;
          }

          .imgback1{
            background-image: url("img/story.png");
          }

          .imgback2{
            background-image: url("img/js.png");
          }

          .imgback3{
            background-image: url("img/inst.png");
          }
        }

      }

    }

  }
  .red-border{
    border: 3px dotted #FFC0CB !important;
  }
  .history_messs{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    max-width: calc(100vw - 350px);
    background-color: #FFFFFF;
    border: 1px solid #CCC;
    margin-top: 40px;
    border-radius: 20px;
    //overflow-x: auto;
    .title{
      margin: 20px;
      text-transform: uppercase;
      font-weight: 500;
      font-size: 1.4rem;
    }
    .history_mess_pen {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      width: calc(100% - 40px);
      padding: 20px;
      position: relative;
      .history_mess_pen_letter{
        width: calc(100% - 20px);
        height: 100px;
        border-radius: 10px;
        border: 1px solid #CCC;
        resize: none;
        outline: none;
        padding: 10px;
      }
      .history_mess_pen_letter:focus{
        border: 1px solid #329DD9;
      }
      .history_mess_pen_btn{
        padding: 10px 30px;
        margin-top: 10px;
        background-color: rgb(18, 19, 56);
        color: #FFFFFF;
        border-radius: 10px;
        cursor: pointer;
        i{
          font-size: 16pt;
          margin-left: 10px;
        }
      }
      .history_mess_pen_btn:hover{
        opacity: 0.8;
      }
    }

    .history_mess_list{
      overflow-x: auto;
      width: calc(100% - 20px);

      .history_mess_list_block{
        margin-bottom: 20px;
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        .history_mess_list_block_ava{
          width: 60px;
          height: 60px;
          background-size: 100%;
          background-position: center center;
          background-repeat: no-repeat;
          border-radius: 50%;
          margin: 20px;
        }
        .history_mess_list_block_content{
          width: calc(100% - 120px);
          padding: 10px;
          border-radius: 10px;
          background-color: #F1F1F1;

          .history_mess_list_block_content_name{
            display: flex;
            justify-content: space-between;
            font-weight: 600;
            margin: 10px;
            .icons{
              display: flex;
              justify-content: flex-start;
              align-items: center;
            }
            i{
              margin-left: 10px;
              cursor: pointer;
              font-size: 1.2rem;
              color: #cccccc;
            }
            i:hover{
              color: black;
            }
            .small{
              font-size: 0.8rem;
            }
          }
          .history_mess_list_block_content_message{
            margin: 10px;
            max-width: 100%;
          }
          .history_mess_list_block_content_dateandstatus{
            width: 100%;
            display: flex;
            justify-content: space-between;
            font-size: 10pt;
            .history_mess_list_block_content_date{
              margin-right: 20px;
            }
            .history_mess_list_block_content_status{
              margin-right: 10px;
            }
          }
          .change-text{
            width: 90%;
            border-radius: 10px;
            border: 1px solid #CCC;
            resize: none;
            outline: none;
            padding: 10px;
            margin: 5px 0 10px 0;
          }
          .cancel,.savechange{
            margin-top: -2px;
            font-size: 0.9rem;
            font-weight: 600;
            text-transform: uppercase;
            color: #aaa;
            cursor: pointer;
            margin-left: 15px;
          }
          .savechange:hover,.cancel:hover{
            color: #333;
          }
          .savechange{

          }

        }

      }
      .autorch{
        background-color: #FFFFFF !important;
        border: 1px solid #CCC;
      }
    }
    .showmore{
      cursor: pointer;
      margin: 10px 40px 30px;
      font-weight: 700;
      color: #aaa;
    }
    .showmore:hover{
      color: #333333;
      text-decoration: underline;
    }
  }
}
@media screen and (max-width: 800px){
  .news_block{
    width: calc(100%);
    margin-top: 50px;

    .news_block_title{
      display: flex;
      justify-content: flex-start;
      align-items: flex-end;
      .news_block_title_name{
        font-size: 24pt;
        margin-right: 50px;
        font-weight: 800;
        height: 35px;
        color: rgb(18, 19, 56);
      }
      .news_block_title_more{
        //margin-top: 5px;
        font-size: 14pt;
        cursor: pointer;
      }
      .news_block_title_create{
        font-size: 14pt;
        padding: 5px 30px;
        margin: 0 0 0 20px;
        border: 1px solid #CCC;
        border-radius: 8px;
        color: #CCC;
        cursor: pointer;
        transition: 0.2s;
        display: none;
      }
      .news_block_title_create:hover{
        border: 1px solid #454545;
        color: #454545;
        transition: 0.2s;
      }
    }
    .news_block_titoffer{
      width: 100%;
      display: flex;
      flex-direction: column;
      //justify-content: flex-start;
      //align-items: flex-end;
      .news_block_title_offer{
        width: 100%;
        display: flex;
        justify-content: center;
        font-size: 14pt;
        padding: 15px 0px;
        margin: 10px 0 0 0px;
        border: 1px solid #CCC;
        border-radius: 8px;
        color: rgb(18, 19, 56);
        cursor: pointer;
        transition: 0.2s;
        background-color: #FFFFFF;
      }
      .news_block_title_offer:hover{
        border: 1px solid #454545;
        color: #454545;
        transition: 0.2s;
      }
    }

    .news_block_list{
      width: 100%;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      .news_block_list_box {
        width: 96%;
        min-width: 260px;
        padding: 1%;
        margin: 1%;
        min-height: 300px;
        max-height: 500px;
        border: none;
        margin-top: 20px;
        border-radius: 10px;
        background-color: #FFF;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;
        cursor: pointer;
        .news_block_list_box_img{
          width: 100%;
          //height: 250px;
          background-size: cover;
          background-repeat: no-repeat;
          background-position: center center;
          border-radius: 7px;
        }
        .news_block_list_box_content{
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: flex-start;
          width: 100%;
          height:calc(100% - 180px);

          .news_block_list_box_content_a{
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            .news_block_list_box_content_xyz{
              display: flex;
              justify-content: flex-start;
              margin-top: 5px;
              .news_block_list_box_content_xyz_x{
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 1px 15px;
                border: 1px solid #454545;
                border-radius: 10px;
                margin-right: 10px;
              }
              .news_block_list_box_content_xyz_y{
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 1px 15px;
                border: 1px solid #454545;
                border-radius: 10px;
                margin-right: 10px;
              }
              .news_block_list_box_content_xyz_z{
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 1px 15px;
                border: 1px solid #454545;
                border-radius: 10px;
                margin-right: 10px;
              }
            }

            .news_block_list_box_content_title{
              font-weight: 800;
              margin-top: 5px;
              margin-bottom: 5px;
              font-size: 11pt;
              padding: 5px;
            }
            .news_block_list_box_content_description{
              margin-bottom: 15px;
              font-size: 10pt;
              padding: 5px;
            }
          }
          .news_block_list_box_content_btns{
            display: flex;
            justify-content: space-between;
            align-items: flex-end;
            width: 100%;
            div{
              margin-right: 15px;
            }
            .rigth{
              display: flex;
              justify-content: right;
              font-size: 10pt;
              padding: 0 5px 5px 5px;
            }
            .left{
              display: flex;
              flex-direction: row;
              justify-content: left;
              padding: 0 5px 5px 5px;

            }
            .views{
              margin: 0 10px;
            }
            .news_block_list_box_content_btns_date{

            }
            .news_block_list_box_content_btns_like{}
            .news_block_list_box_content_btns_comment{}
          }



        }

      }
    }
  }
  .create_new_post{
    width: 100%;
    //font-size: 16pt;
    .create_new_post_title{
      width: calc(100% - 40px);
      font-size: 18pt;
      font-weight: 600;
      color:rgba(18,19,56,1);
      padding: 0 20px;
    }
    .create_new_post_tools{
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;
      margin-top: 10px;
      padding: 10px 20px;

      .create_new_post_tools_plusblock{}
      .create_new_post_tools_devbtn{}
      .create_new_post_tools_publish{
        padding: 8px;
      }
      div{
        margin-right: 20px;
        padding: 20px 20px;
        border: 1px solid #CCC;
        border-radius: 4px;
        cursor: pointer;
        color: #454545;
        transition: 0.2s;
      }
      div:hover{
        color: rgba(18,19,56,1);
        border: 1px solid rgba(18,19,56,1);
        transition: 0.2s;
      }
    }

    .create_new_post_worklist{

      width: calc(100% - 40px);
      padding: 20px;
      background-color: #FFF;
      border-radius: 10px;
      .create_new_post_worklist_header{
        width: calc(100% - 46px);
        padding: 20px;
        border: 3px dotted #CCC;
        border-radius: 10px;
        font-size: 16pt;
        font-weight: 600;
        color:rgba(18,19,56,1);
        text-align: center;
        outline: none;
        transition: .2s;
      }

      .create_new_post_worklist_header:hover{
        border: 3px dotted #454545;
        transition: .2s;
      }

      .create_new_post_worklist_header:active{
        border: 3px dotted #454545;
        transition: .2s;
      }

      .create_new_post_worklist_header:focus{
        border: 3px dotted #454545;
        transition: .2s;
      }

      .create_new_post_worklist_mainimg{
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #E3E3E3;
        width: calc(100% - 46px);
        height: 200px;
        border: 3px dotted #CCC;
        margin: 10px 0 0 0;
        padding: 10px 20px;
        border-radius: 10px;
        cursor: pointer;
        transition: .2s;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;
        i{
          color: #CCC;
          font-size: 24pt;
          border: 1px solid #CCC;
          padding: 10px;
          border-radius: 3px;
          transition: .2s;
        }
      }
      .create_new_post_worklist_mainimg:hover{
        transition: .2s;
        border: 3px dotted #454545;
        i{
          color: #454545;
          border: 1px solid #454545;
          transition: .2s;
        }
      }



      .create_new_post_worklist_content{
        margin-top: 10px;
        display: flex;
        flex-direction: column;
        .create_new_post_worklist_content_smalltext{
        }
        .create_new_post_worklist_content_title{
          font-size: 18pt;
        }
        .create_new_post_worklist_content_longtext{

        }
        .create_new_post_worklist_content_imgtext{
          display: flex;
          justify-content: space-between;
          div, textarea{
            width: calc(90% / 2)
          }
          .create_new_post_worklist_content_imgtext_img{
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: #E3E3E3;
            height: 400px;
            border: 3px dotted #CCC;
            padding: 10px 20px;
            border-radius: 10px;
            cursor: pointer;
            transition: .2s;
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center center;
            i{
              color: #CCC;
              font-size: 24pt;
              border: 1px solid #CCC;
              padding: 10px;
              border-radius: 3px;
              transition: .2s;
            }
          }
          .create_new_post_worklist_content_imgtext_img:hover{
            transition: .2s;
            border: 3px dotted #454545;
            i{
              color: #454545;
              border: 1px solid #454545;
              transition: .2s;
            }
          }
          .create_new_post_worklist_content_imgtext_text{
            font-size: 18pt;
          }
        }
        div, input, textarea{
          width: calc(100% - 46px);
          padding: 10px 20px;
          min-height: 120px;
          border: 3px dotted #CCC;
          border-radius: 10px;
          //margin: 10px 0 0 0;
        }

        div, input, textarea:hover{
          border: 3px dotted #454545;
          transition: .2s;
        }

        div, input, textarea:active{
          border: 3px dotted #454545;
          transition: .2s;
        }

        textarea:focus{
          border: 3px dotted #454545;
          transition: .2s;
        }

        input:focus{
          border: 3px dotted #454545;
          transition: .2s;
        }

        div:focus{
          border: 3px dotted #454545;
          transition: .2s;
        }

        .plus_btn_create_strock{
          display: flex;
          flex-wrap: wrap;
          border: none;
          min-height: 10px;
          transition: .2s;
        }

        .plus_btn_create_strock_btn{
          min-height: 20px;
          border: 1px solid #CCC;
          color: #CCC;
          width: auto;
          max-height: 30px !important;
          cursor: pointer;
          margin-right: 10px;
          padding: 5px 30px;
        }
        .plus_btn_create_strock_btn:hover{
          color: #454545;
          border: 1px solid #454545;

        }

        .plus_btn_create_strock:hover{
          div{
            display: flex;
          }
        }

        .create_new_post_worklist_content_tripleimgs{
          display: flex;
          justify-content: space-between;
          .create_new_post_worklist_content_tripleimgs_img{
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: #E3E3E3;
            height: 400px;
            border: 3px dotted #CCC;
            padding: 10px 20px;
            border-radius: 10px;
            cursor: pointer;
            transition: .2s;
            width: calc(85% / 3);
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center center;
            i{
              color: #CCC;
              font-size: 24pt;
              border: 1px solid #CCC;
              padding: 10px;
              border-radius: 3px;
              transition: .2s;
            }
          }
          .create_new_post_worklist_content_tripleimgs_img:hover{
            transition: .2s;
            border: 3px dotted #454545;
            i{
              color: #454545;
              border: 1px solid #454545;
              transition: .2s;
            }
          }
        }

      }

    }

  }
  .view_new_post{
    width: 100%;
    //font-size: 16pt;
    .view_new_post_title{
      width: calc(100% - 40px);
      font-size: 18pt;
      font-weight: 600;
      color:rgba(18,19,56,1);
      padding: 0 20px;
    }
    .view_new_post_tools{
      display: flex;
      justify-content: space-between;
      flex-wrap: nowrap;
      margin-top: 10px;
      padding: 0px 0px;

      span{
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .view_new_post_tools_plusblock{
        padding: 5px;
        margin-right: 5px !important;
      }
      .view_new_post_tools_devbtn{}
      .view_new_post_tools_publish.disable{
        display: none;
      }
      .view_new_post_tools_publish{
        margin-right: 5px !important;
      }
      div,a{
        margin-right: 20px;
        padding: 5px 5px;
        border: 1px solid #CCC;
        border-radius: 4px;
        cursor: pointer;
        color: #454545;
        transition: 0.2s;

      }
      div:hover,a:hover{
        color: rgba(18,19,56,1);
        border: 1px solid rgba(18,19,56,1);
        transition: 0.2s;
      }
    }

    .view_new_post_worklist{

      width:100%;

      border-radius: 10px;
      .view_new_post_worklist_header{
        width: calc(100% - 40px);
        padding: 20px;
        font-size: 16pt;
        font-weight: 600;
        color:rgba(18,19,56,1);
        text-align: center;
        outline: none;
        transition: .2s;
      }

      .view_new_post_worklist_mainimg{
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #E3E3E3;
        background-size: contain;
        background-position: center center;
        border-radius: 10px;
        transition: .2s;

      }.view_new_post_worklist_mainimg.big{
         height: 400px;
       }
      .biggest{
        height: 200px;
      }
      .view_new_post_worklist_content{
        margin-top: 10px;
        display: flex;
        flex-direction: column;
        .view_new_post_worklist_content_smalltext{
          font-size: 12pt;
          font-weight: 600;
        }
        .view_new_post_worklist_content_title{
          font-size: 18pt;
        }
        .view_new_post_worklist_content_longtext{
          font-size: 11pt;
        }
        .view_new_post_worklist_content_imgtext{
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;
          div{
            width: calc(90%)
          }
          .view_new_post_worklist_content_imgtext_img{
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: #E3E3E3;
            background-image: url("img/bundle.png");
            background-size: cover;
            background-position: center center;
            padding: 10px 20px;
            border-radius: 10px;
            cursor: pointer;
            transition: .2s;

          }

          .view_new_post_worklist_content_imgtext_text{
            font-size: 11pt;
            font-weight: 500;
          }
        }
        div{
          width: calc(100% - 40px);
          padding: 10px 20px;
          border-radius: 10px;
          //margin: 10px 0 0 0;
        }




        .view_new_post_worklist_content_tripleimgs{
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          .view_new_post_worklist_content_tripleimgs_img{
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: #E3E3E3;
            padding: 0;
            border-radius: 10px;
            cursor: pointer;
            transition: .2s;
            width: calc(100%);
            margin-bottom: 10px;
            background-position: center center;
            background-size: cover;
          }

          .imgback1{
            background-image: url("img/story.png");
          }

          .imgback2{
            background-image: url("img/js.png");
          }

          .imgback3{
            background-image: url("img/inst.png");
          }
        }

      }

    }

  }
  .red-border{
    border: 3px dotted #FFC0CB !important;
  }
  .history_messs{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    min-width: calc(100vw - 80px);
    //max-width: calc(100vw - 350px);
    background-color: #FFFFFF;
    border: 1px solid #CCC;
    margin-top: 40px;
    border-radius: 20px;
    //overflow-x: auto;
    .title{
      margin: 20px;
      text-transform: uppercase;
      font-weight: 500;
      font-size: 1.0rem;
    }
    .history_mess_pen {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      width: calc(100% - 40px);
      padding: 20px;
      position: relative;
      .history_mess_pen_letter{
        width: calc(100% - 20px);
        height: 100px;
        border-radius: 10px;
        border: 1px solid #CCC;
        resize: none;
        outline: none;
        padding: 10px;
      }
      .history_mess_pen_letter:focus{
        border: 1px solid #329DD9;
      }
      .history_mess_pen_btn{
        padding: 10px 30px;
        margin-top: 10px;
        background-color: rgb(18, 19, 56);
        color: #FFFFFF;
        border-radius: 10px;
        cursor: pointer;
        i{
          font-size: 16pt;
          margin-left: 10px;
        }
      }
      .history_mess_pen_btn:hover{
        opacity: 0.8;
      }
    }

    .history_mess_list{
      overflow-x: auto;
      width: calc(100%);

      .history_mess_list_block{
        margin-bottom: 20px;
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        .history_mess_list_block_ava{

          width: 40px !important;
          height: 40px  !important;
          background-size: 100%;
          background-position: center center;
          background-repeat: no-repeat;
          border-radius: 50%;
          margin: 10px  !important;
        }
        .history_mess_list_block_content{
          width: calc(100% - 60px)   !important;
          padding: 10px;
          border-radius: 10px;
          background-color: #F1F1F1;

          .history_mess_list_block_content_name{
            display: flex;
            justify-content: space-between;
            font-weight: 600;
            margin: 10px;
            .icons{
              display: flex;
              justify-content: flex-start;
              align-items: center;
            }
            i{
              margin-left: 10px;
              cursor: pointer;
              font-size: 1.2rem;
              color: #cccccc;
            }
            i:hover{
              color: black;
            }
            .small{
              font-size: 0.8rem;
            }
          }
          .history_mess_list_block_content_message{
            margin: 10px;
            max-width: 100%;
          }
          .history_mess_list_block_content_dateandstatus{
            width: 100%;
            display: flex;
            justify-content: space-between;
            font-size: 10pt;
            .history_mess_list_block_content_date{
              margin-right: 20px;
            }
            .history_mess_list_block_content_status{
              margin-right: 10px;
            }
          }
          .change-text{
            width: 90%;
            border-radius: 10px;
            border: 1px solid #CCC;
            resize: none;
            outline: none;
            padding: 10px;
            margin: 5px 0 10px 0;
          }
          .cancel,.savechange{
            margin-top: -2px;
            font-size: 0.9rem;
            font-weight: 600;
            text-transform: uppercase;
            color: #aaa;
            cursor: pointer;
            margin-left: 15px;
          }
          .savechange:hover,.cancel:hover{
            color: #333;
          }
          .savechange{

          }

        }

      }
      .autorch{
        background-color: #FFFFFF !important;
        border: 1px solid #CCC;
      }
    }
    .showmore{
      cursor: pointer;
      margin: 10px 40px 30px;
      font-weight: 700;
      color: #aaa;
    }
    .showmore:hover{
      color: #333333;
      text-decoration: underline;
    }
  }
}
.news_block_list_box_img{
  aspect-ratio: 2.5 / 1 !important;
  position: relative;
  .back{
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(1,1,1,0.2);
    transition: 1s;
  }
  &:hover{
    .back{
      position: absolute;
      width: 100%;
      height: 100%;
      background-color: rgba(1,1,1,0.0);
    }
  }
}

@media screen and (min-width: 1400px) and (max-width: 1600px){
  .news_block_list_box_img{
    //height: 200px !important;
  }
}
@media screen and (min-width: 1000px) and (max-width: 1400px){
  .news_block_list_box_img{
    //height: 350px !important;
  }
}
@media screen and (min-width: 550px) and (max-width: 800px){
  .news_block_list_box_img{
    //height: 400px !important;
  }
  .news_block_list_box_content_btns{

  }
}
@media screen and (min-width: 300px) and (max-width: 550px){
  .news_block_list_box_img{
    //height: 220px !important;
  }
  .news_block_list_box_content_btns{

  }
  .history_messs {
    max-width: none !important;
  }
  .news_block_list_box_content{
    height: calc(100% - 130px) !important;
  }
  .news_block_list_box_content_btns_date{
    padding: 0 5px 5px 5px;
  }
}
.checkbox-container {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 20px;
  cursor: pointer;
  font-size: 18px;
  user-select: none;
  margin-top: 20px;
  font-weight: 600;

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }

  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: #eee;
    border-radius: 5px;

    &::after {
      content: '';
      position: absolute;
      display: none;
      left: 8px;
      top: 3px;
      width: 6px;
      height: 12px;
      border: solid white;
      border-width: 0 3px 3px 0;
      transform: rotate(45deg);
    }
  }

  input:checked ~ .checkmark {
    background-color: rgb(18, 19, 56);
  }

  input:checked ~ .checkmark::after {
    display: block;
  }
}
.history_messs{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  max-width: calc(100vw - 350px);
  background-color: #FFFFFF;
  border: 1px solid #CCC;
  margin-top: 40px;
  border-radius: 20px;
  //overflow-x: auto;
  .title{
    margin: 20px;
    text-transform: uppercase;
    font-weight: 500;
    font-size: 1.4rem;
  }
  .history_mess_pen {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    width: calc(100% - 40px);
    padding: 20px;
    position: relative;
    .history_mess_pen_letter{
      width: calc(100% - 20px);
      height: 100px;
      border-radius: 10px;
      border: 1px solid #CCC;
      resize: none;
      outline: none;
      padding: 10px;
    }
    .history_mess_pen_letter:focus{
      border: 1px solid #329DD9;
    }
    .history_mess_pen_btn{
      padding: 10px 30px;
      margin-top: 10px;
      background-color: rgb(18, 19, 56);
      color: #FFFFFF;
      border-radius: 10px;
      cursor: pointer;
      i{
        font-size: 16pt;
        margin-left: 10px;
      }
    }
    .history_mess_pen_btn:hover{
      opacity: 0.8;
    }
  }

  .history_mess_list{
    overflow-x: auto;
    width: calc(100% - 20px);

    .history_mess_list_block{
      margin-bottom: 20px;
      width: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      .history_mess_list_block_ava{
        width: 60px;
        height: 60px;
        background-size: 100%;
        background-position: center center;
        background-repeat: no-repeat;
        border-radius: 50%;
        margin: 20px;
      }
      .history_mess_list_block_content{
        width: calc(100% - 120px);
        padding: 10px;
        border-radius: 10px;
        background-color: #F1F1F1;

        .history_mess_list_block_content_name{
          display: flex;
          justify-content: space-between;
          font-weight: 600;
          margin: 10px;
          .icons{
            display: flex;
            justify-content: flex-start;
            align-items: center;
          }
          i{
            margin-left: 10px;
            cursor: pointer;
            font-size: 1.2rem;
            color: #cccccc;
          }
          i:hover{
            color: black;
          }
          .small{
            font-size: 0.8rem;
          }
        }
        .history_mess_list_block_content_message{
          margin: 10px;
          max-width: 100%;
        }
        .history_mess_list_block_content_dateandstatus{
          width: 100%;
          display: flex;
          justify-content: space-between;
          font-size: 10pt;
          .history_mess_list_block_content_date{
            margin-right: 20px;
          }
          .history_mess_list_block_content_status{
            margin-right: 10px;
          }
        }
        .change-text{
          width: 90%;
          border-radius: 10px;
          border: 1px solid #CCC;
          resize: none;
          outline: none;
          padding: 10px;
          margin: 5px 0 10px 0;
        }
        .cancel,.savechange{
          margin-top: -2px;
          font-size: 0.9rem;
          font-weight: 600;
          text-transform: uppercase;
          color: #aaa;
          cursor: pointer;
          margin-left: 15px;
        }
        .savechange:hover,.cancel:hover{
          color: #333;
        }
        .savechange{

        }

      }

    }
    .autorch{
      background-color: #FFFFFF !important;
      border: 1px solid #CCC;
    }
  }
  .showmore{
    cursor: pointer;
    margin: 10px 40px 30px;
    font-weight: 700;
    color: #aaa;
  }
  .showmore:hover{
    color: #333333;
    text-decoration: underline;
  }
}
.delete-box{
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  p{
    width: 100%;
    text-align: center;
  }
}

.view_new_post_worklist_mainimg{
  img{
    width: 100%;
    border-radius: 10px;
  }
  background-repeat: no-repeat;
}
.view_new_post_worklist_content_tripleimgs_img,.view_new_post_worklist_content_imgtext_img{
  aspect-ratio: 1 / 1 !important;
}

.news-status{
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 10px;
  .circle{
    background-color: rgba(18, 19, 56,0.08);
    width: 50px;
    height: 20px;
    border-radius: 30px;
    padding: 5px 15px 5px 10px;
    display: flex;
    align-items: center;
    font-size: 0.9rem;

    i{
      color: #333333;
      margin-right: 10px;
    }
  }
  .liked{
    color: darkred !important;
  }
  .cliker{
    cursor: pointer;
  }
  .cliker:hover{
    background-color: rgba(18, 19, 56,0.3);
  }
  .rigth-box{
    width: 50%;
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: center;
    .circle{
      margin-left: 20px;
    }
  }
  .left-box{
    width: 50%;
    display: flex;
    flex-direction: row;
    justify-content: right;
    align-items: center;
    .circle{
      margin-right: 20px;
    }
  }
}
.news_block_list_box{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.link{
  display: flex;
  flex-direction: column;
}
.news_block_list_box_content_btns{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  .left{
    display: flex;
    flex-direction: row;
    justify-content: left;
    .news_block_list_box_content_btns_date{
      margin-right: 10px;
    }
    .circle{
      margin: 0 5px;
      i{
        margin: 0 3px;
      }
    }
  }
  .rigth{
    .views{
      margin-right: 10px;
      i{
        margin-right: 5px;
      }
    }

  }
  i{
    color: #555;
  }
}
.blue-link{
  color: blue;
  &:hover{
    color: darkblue;
  }
}

.survey-row {
  .grey{
    color: #aaaaaa;
    cursor: default;
  }
}